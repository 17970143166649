import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';
import { IStaticMethods } from 'preline/src/static/interfaces';
import { CookieService } from './services/cookie.service';

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  host: { 'class': 'text-text-primary' },
  standalone: true,
  imports: [NavbarComponent, RouterOutlet],
})
export class AppComponent implements OnInit {
  private router: Router = inject(Router)
  private cookieService: CookieService = inject(CookieService)

  ngOnInit() {
    if (typeof (window) !== 'undefined') {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.cookieService.subscribe();
          setTimeout(() => {
            window.HSStaticMethods.autoInit();
          }, 100);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (typeof (window) !== 'undefined') {
      this.cookieService.unsubscribe();
    }
  }
}
