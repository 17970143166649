import { inject, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    private popupOpenSubscription!: Subscription;
    private popupCloseSubscription!: Subscription;
    private initializingSubscription!: Subscription;
    private initializedSubscription!: Subscription;
    private initializationErrorSubscription!: Subscription;
    private statusChangeSubscription!: Subscription;
    private revokeChoiceSubscription!: Subscription;
    private noCookieLawSubscription!: Subscription;

    private ccService: NgcCookieConsentService = inject(NgcCookieConsentService);
    private translocoService: TranslocoService = inject(TranslocoService);

    subscribe() {
        this.translocoService.selectTranslateObject('cookie').subscribe((translations) => {
            this.ccService.getConfig().content = this.ccService.getConfig().content || {};

            this.ccService.getConfig().content!.header = translations.header;
            this.ccService.getConfig().content!.message = translations.message;
            this.ccService.getConfig().content!.dismiss = translations.dismiss;
            this.ccService.getConfig().content!.allow = translations.allow;
            this.ccService.getConfig().content!.deny = translations.deny;
            this.ccService.getConfig().content!.link = translations.link;
            this.ccService.getConfig().content!.policy = translations.policy;
            this.ccService.destroy();
            this.ccService.init(this.ccService.getConfig());
        });


        this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
            () => {
            });

        this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
            () => {
                this.ccService.toggleRevokeButton(false);
            });

        this.initializingSubscription = this.ccService.initializing$.subscribe(
            (event: NgcInitializingEvent) => {
            });

        this.initializedSubscription = this.ccService.initialized$.subscribe(
            (event: any) => {
            });

        this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
            (event: NgcInitializationErrorEvent) => {
            });

        this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
            (event: NgcStatusChangeEvent) => {
            });

        this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
            () => {
            });

        this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
            (event: NgcNoCookieLawEvent) => {
            });
    }

    unsubscribe() {
        this.popupOpenSubscription.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
        this.initializingSubscription.unsubscribe();
        this.initializedSubscription.unsubscribe();
        this.initializationErrorSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
        this.noCookieLawSubscription.unsubscribe();
    }
} 