import { afterNextRender, AfterViewInit, Component, ElementRef, inject, OnDestroy, ViewChild } from '@angular/core';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { map, Subscription } from 'rxjs';
import { LinkItem, NavbarItem } from '../../types/navbar.interface';
import { BaseServiceComponent } from 'src/app/shared/base.service.component';
import { gsap } from "gsap";
import Flip from "gsap/dist/Flip";
import { derivedAsync } from 'ngxtension/derived-async';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  standalone: true,
  imports: [
    RouterLink,
    NgFor,
    NgIf,
    NgClass,
    NgTemplateOutlet
  ],
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent extends BaseServiceComponent implements AfterViewInit, OnDestroy {
  navbar$ = derivedAsync(() => this._apiService.getNavbar(this._languageService.getLanguageCode))
  menuVisible: boolean = false;
  slug: string = 'home';
  navbarWidth: string = 'w-[480px]';
  @ViewChild('logoPlaceholder', { static: false }) logoPlaceholderRef!: ElementRef;

  @ViewChild('navbarItems') set navbarItemsRef(value: ElementRef) {
    if (value) {
      if (typeof (document) === 'undefined') return;
      document.addEventListener('scroll', () => {
        const navbarElement = value.nativeElement;
        const state = Flip.getState(navbarElement);

        const scrollTop = window.scrollY;
        if (scrollTop > 0) {
          navbarElement.classList.add('w-full');
          navbarElement.classList.remove(this.navbarWidth);
        } else {
          navbarElement.classList.remove('w-full');
          navbarElement.classList.add(this.navbarWidth);
        }

        Flip.from(state, {
          duration: 0.5,
          ease: 'power4.out'
        });
      });
    }
  }

  private router = inject(Router)
  private routerSubscription: Subscription | undefined;

  constructor() {
    super();
    gsap.registerPlugin(Flip);
    this.setupRouterEvents();
    afterNextRender({
      read: () => {
        this.routerSubscription = this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            const button = document.getElementById("hs-navbar-example-collapse")
            if (!button) {
              return
            }
            //@ts-ignore
            window?.HSCollapse?.hide(button)
          }
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.handleLogoDisplay();
  }

  private setupRouterEvents() {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateSlug();
        this.setNavbarWidth();
        this.handleLogoDisplay();
      }
    });
  }

  setNavbarWidth() {
    if (this.slug === 'home') {
      this.navbarWidth = 'w-[480px]';
    } else {
      this.navbarWidth = 'w-3/4';
    }
  }

  handleLogoDisplay() {
    setTimeout(() => {
      if (this.logoPlaceholderRef) {
        const logoElement = this.logoPlaceholderRef.nativeElement;
        this.slug === 'home' ? logoElement.classList.remove('lg:block') : logoElement.classList.add('lg:block');
      }
    });
  }

  isActiveString(link: string): boolean {
    return link === `/${this.slug}`;
  }

  isActiveLink(linkItem: LinkItem): boolean {
    return linkItem.link === `/${this.slug}`;
  }

  isActiveSubLink(item: NavbarItem): boolean {
    return item.subLinkItems.some((subLinkItem) => this.isActiveLink(subLinkItem));
  }

  getNavbarItemsExceptLast() {
    return this.navbar$()?.data.navbar.data.attributes.navbarItems.slice(0, -1);
  }

  getLastNavbarItem() {
    return this.navbar$()?.data.navbar.data.attributes.navbarItems.slice(-1)[0];
  }

  private updateSlug() {
    const url = this.router.url.split('/');
    if (url.length > 1) {
      this.slug = url[2];
    } else {
      this.slug = 'home';
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }
}

