import { Routes } from '@angular/router';
import { PageResolver } from './services/page.resolver.service';

export const routes: Routes = [
  {
    path: ':languageCode/events/:uuid',
    loadComponent: () => import('./components/events/events.component').then(m => m.EventsComponent),
  },
  {
    path: 'events/:uuid',
    redirectTo: route => {
      return `de/events/${route.params['uuid']}`;
    }
  },
  {
    path: ':languageCode/news/:slug',
    loadComponent: () => import('./components/news-full-page/news-full-page.component').then(m => m.NewsFullPageComponent),
  },
  {
    path: 'news/:slug',
    redirectTo: route => {
      return `de/news/${route.params['slug']}`;
    }
  },
  {
    path: ':languageCode/email',
    children: [
      {
        path: 'confirmation/:uuid/:email',
        loadComponent: () => import('./components/email/email-confirmation/email-confirmation.component').then(m => m.EmailConfirmationComponent),
      },
      {
        path: 'unsubscribe/:uuid/:email',
        loadComponent: () => import('./components/email/email-unsubscribe/email-unsubscribe.component').then(m => m.EmailUnsubscribeComponent),
      }
    ]
  },
  {
    path: 'email/confirmation/:uuid/:email',
    redirectTo: route => {
      return `de/email/confirmation/${route.params['uuid']}/${route.params['email']}`;
    }
  },
  {
    path: 'email/unsubscribe/:uuid/:email',
    redirectTo: route => {
      return `de/email/unsubscribe/${route.params['uuid']}/${route.params['email']}`;
    }
  },
  {
    path: ':languageCode/:slug',
    loadComponent: () => import('./renderer/renderer.component').then(m => m.RendererComponent),
    resolve: {
      page: PageResolver
    }
  },
  {
    path: ':languageCode',
    redirectTo: route => {
      const languageCode = route.params['languageCode'];
      if (languageCode === 'de' || languageCode === 'en') {
        return `${route.params['languageCode']}/home`;
      }
      return `de/${languageCode}`;
    },
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/de/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    loadComponent: () => import('./renderer/renderer.component').then(m => m.RendererComponent),
  }
];
