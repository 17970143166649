import { NgcCookieConsentConfig } from 'ngx-cookieconsent'

export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: "thenightrace.at"
    },
    position: "bottom-right",
    theme: "edgeless",
    palette: {
        popup: {
            background: "#2b2b2b",
            text: "#f2f2f2",
            link: "#ffffff"
        },
        button: {
            background: "#fac000",
            text: "#2b2b2b",
            border: "transparent"
        }
    },
    type: "info",
    content: {
        message: "This website uses cookies to ensure you get the best experience on our website.",
        dismiss: "Got it!",
        deny: "Refuse cookies",
        link: "Learn more",
        href: "/datenschutz",
        policy: "Cookie Policy"
    }
}