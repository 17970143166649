import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, MaybeAsync, Resolve, RouterStateSnapshot } from '@angular/router';
import { Page } from '../types/page.interface';
import { ApiService } from './api.service';
import { catchError, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageResolver implements Resolve<Page> {
    constructor(private apiService: ApiService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<Page> {
        return this.apiService.getPageBySlug(route.params['slug'], route.params['languageCode'])
            .pipe(
                catchError(() => {
                    return of<Page>({
                        data: {
                            pages: {
                                data: []
                            }
                        }
                    })
                }
                )
            )
    }
}