@if (navbar$()?.data) {
  <nav
    class="top-0 z-10 fixed p-4 w-full max-w-[100svw] font-futo lg:text-base"
    id="navbar"
  >
    <div
      class="lg:right-4 lg:absolute flex justify-between items-center lg:items-start gap-2 w-full lg:w-fit"
    >
      <a
        routerLink="/{{ this._languageService.getLanguageCode }}/home"
        class="lg:hidden transition-transform hover:scale-105"
      >
        <img
          src="assets/images/header/logo.svg"
          alt="nightrace logo"
          class="w-24 h-auto"
        />
      </a>
      <ng-container *ngIf="getLastNavbarItem() as item">
        <a
          class="bg-primary clip-path-4 text-text-secondary transition-[filter,transform] shine-effect {{
            navbarItemClasses
          }}"
          aria-current="page"
          routerLink="/{{ this._languageService.getLanguageCode }}{{
            item.linkItem.link
          }}"
          >{{ item.linkItem.text }}</a
        >
      </ng-container>
      <div
        class="relative z-10 lg:flex items-center gap-4 hidden self-center shrink-0"
      >
        <ng-container *ngTemplateOutlet="navbarActions"></ng-container>
      </div>
      <!-- burger -->
      <div class="lg:hidden self-center">
        <button
          type="button"
          class="relative flex justify-center items-center gap-x-2 border-neutral-700 bg-neutral-600 hover:bg-background-secondary-lighter focus:bg-neutral-500 disabled:opacity-50 shadow-sm border rounded-lg disabled:pointer-events-none hs-collapse-toggle size-7 focus:outline-none"
          id="hs-navbar-example-collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          aria-controls="hs-navbar-example"
          data-hs-collapse="#hs-navbar-example"
        >
          <svg
            class="hs-collapse-open:hidden shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="3" x2="21" y1="6" y2="6" />
            <line x1="3" x2="21" y1="12" y2="12" />
            <line x1="3" x2="21" y1="18" y2="18" />
          </svg>
          <svg
            class="hs-collapse-open:block hidden shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
          <span class="sr-only">Toggle navigation</span>
        </button>
      </div>
    </div>
    <!-- navitems -->
    <div
      id="hs-navbar-example"
      class="lg:block hidden transition-all duration-300 overflow-hidden hs-collapse"
      aria-labelledby="hs-navbar-example-collapse"
    >
      @let navbarItemClasses =
        "hover:brightness-90 ~text-sm/base ~/lg:~px-[1.6rem]/8 ~/lg:~py-[.6rem]/4 min-w-fit hover:scale-105";
      <div
        #navbarItems
        class="relative lg:right-auto flex lg:flex-row flex-col flex-wrap lg:items-start gap-2 bg-background-secondary-darker lg:bg-transparent mt-6 lg:mt-auto p-4 lg:p-0 border border-background-secondary-lighter lg:border-none rounded-lg max-w-fit"
        [ngClass]="navbarWidth"
      >
        <a
          #logoPlaceholder
          routerLink="/{{ this._languageService.getLanguageCode }}/home"
          class="lg:block hidden transition-transform hover:scale-105"
        >
          <img
            src="assets/images/header/logo.svg"
            alt="nightrace logo"
            class="w-28 h-auto"
          />
        </a>
        <ng-container
          *ngFor="let item of getNavbarItemsExceptLast(); let i = index"
        >
          <div
            #navbarItem
            class="flex"
            *ngIf="!item.subLinkItems.length; else dropdown"
          >
            <a
              class="bg-background-secondary bg-gradient-to-br from-background-secondary to-background-secondary-darker transition-[filter,transform,color] hover:text-primary {{
                navbarItemClasses
              }}"
              [ngClass]="[
                isActiveLink(item.linkItem)
                  ? ' text-primary focus:text-secondary'
                  : ' text-text-primary focus:text-primary',
                'clip-path-' + i,
              ]"
              routerLink="/{{ this._languageService.getLanguageCode }}{{
                item.linkItem.link
              }}"
              >{{ item.linkItem.text }}</a
            >
          </div>
          <ng-template #dropdown>
            <div
              #navbarItem
              class="[--strategy:static] lg:[--strategy:fixed] min-w-fit hs-dropdown [--adaptive:none]"
            >
              <button
                id="hs-navbar-example-dropdown-{{ i }}"
                type="button"
                class="bg-background-secondary bg-gradient-to-br from-background-secondary to-background-secondary-darker hs-dropdown-toggle transition-[filter,transform,color] hover:text-primary {{
                  navbarItemClasses
                }}"
                [ngClass]="[
                  isActiveLink(item.linkItem)
                    ? ' text-primary focus:text-secondary'
                    : ' text-text-primary focus:text-primary',
                  'clip-path-' + i,
                ]"
                aria-haspopup="menu"
                aria-label="Mega Menu"
              >
                <div class="flex items-center">
                  {{ item.linkItem.text }}
                  <svg
                    class="hs-dropdown-open:-rotate-180 lg:hs-dropdown-open:rotate-0 duration-300 ms-1 shrink-0 size-4"
                    [ngClass]="{
                      'bg-primary stroke-text-secondary rounded':
                        isActiveSubLink(item),
                    }"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="m6 9 6 6 6-6" />
                  </svg>
                </div>
              </button>
              <div
                class="top-full before:-top-5 z-10 before:absolute space-y-1 border-neutral-700 hidden bg-background-secondary opacity-0 hs-dropdown-open:opacity-100 lg:shadow-md p-1 lg:border divide-neutral-700 lg:w-48 before:w-full before:h-5 transition-all duration-[150ms] ease-in-out hs-dropdown-menu before:start-0"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="hs-navbar-example-dropdown"
              >
                <a
                  *ngFor="let subItem of item.subLinkItems"
                  class="flex items-center gap-x-3.5 focus:bg-neutral-700 px-3 py-2 text-sm text-text-primary hover:scale-105 hover:text-primary focus:outline-none focus:text-primary transition-transform"
                  [ngClass]="[
                    isActiveLink(subItem)
                      ? ' text-primary focus:text-secondary'
                      : ' text-text-primary focus:text-primary',
                  ]"
                  routerLink="/{{ this._languageService.getLanguageCode }}{{
                    subItem.link
                  }}"
                >
                  {{ subItem.text }}
                </a>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <div
          class="relative z-10 flex items-center gap-4 lg:hidden self-center shrink-0"
        >
          <ng-container *ngTemplateOutlet="navbarActions"></ng-container>
        </div>
      </div>
    </div>
  </nav>
}

<ng-template #navbarActions>
  <a
    routerLink="/{{ this._languageService.getLanguageCode }}/wetter"
    class="duration-100 shrink-0 size-8 hover:scale-105 bg-background-secondary p-1 border border-background-secondary-lighter rounded-lg"
  >
    <img
      src="assets/icons/weather.svg"
      alt="weather icon"
      class="w-full h-full object-contain"
      loading="lazy"
    />
  </a>

  <img
    sizes="(max-width: 290px) 100vw, 290px"
    srcset="
      assets/icons/german-language_tdogwo/german-language_tdogwo_c_scale,w_150.webp 150w,
      assets/icons/german-language_tdogwo/german-language_tdogwo_c_scale,w_290.webp 290w
    "
    src="assets/icons/german-language_tdogwo/german-language_tdogwo_c_scale,w_290.webp"
    alt="german language flag"
    class="~h-5/6 hover:scale-105 duration-100 cursor-pointer"
    loading="lazy"
    [ngClass]="{
      'outline outline-2 outline-success':
        this._languageService.isLanguageSelected('de'),
    }"
    (click)="this._languageService.changeLanguage('de')"
  />

  <img
    sizes="(max-width: 318px) 100vw, 318px"
    srcset="
      assets/icons/english-language_qod1gg/english-language_qod1gg_c_scale,w_150.webp 150w,
      assets/icons/english-language_qod1gg/english-language_qod1gg_c_scale,w_318.webp 318w
    "
    src="assets/icons/english-language_qod1gg/english-language_qod1gg_c_scale,w_318.webp"
    [ngClass]="{
      'outline outline-2 outline-success':
        this._languageService.isLanguageSelected('en'),
    }"
    (click)="this._languageService.changeLanguage('en')"
    loading="lazy"
    alt="english language flag"
    class="~h-5/6 hover:scale-105 duration-100 cursor-pointer"
  />
</ng-template>
