import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, Router, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { routes } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoader } from 'src/transloco-loader';
import { LIGHTBOX_CONFIG, LightboxConfig } from 'ng-gallery/lightbox';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideNgcCookieConsent } from 'ngx-cookieconsent';
import { cookieConfig } from './cookie.config';
import * as Sentry from "@sentry/angular";

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes, withComponentInputBinding(), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
        provideAnimationsAsync(),
        importProvidersFrom(BrowserModule, FormsModule, ReactiveFormsModule, BrowserAnimationsModule),
        provideHttpClient(withFetch()),
        provideTransloco({
            config: {
                availableLangs: ['de', 'en'],
                defaultLang: 'de',
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoHttpLoader
        }),
        {
            provide: LIGHTBOX_CONFIG,
            useValue: {} as LightboxConfig
        },
        provideNgcCookieConsent(cookieConfig),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
};